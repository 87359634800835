import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import ProductPrice from './ProductPrice';
import { ReplacementOrderModal } from './ReplacementOrderModal';
import { FulfillmentsTable } from './Table';
import { InfoTooltip } from './Tooltip';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { TRACKING_URL } from '../constants';
import { calculatePriceAfterDiscountWithTax, parseNumber } from '../financialUtils';
import {
  Customer,
  Fulfillment,
  Order,
  OrderProduct,
  Pet,
  Shipment,
  FulfillmentStatus,
  FulfillmentProduct,
  Item,
} from '../pages/types';
import { FulfillmentHeader } from '../tableHeaders';
import { BOTTOM_TOAST } from '../toastUtils';

interface OrderFulfillmentsProps {
  customer: Customer;
  order: Order;
  refreshCustomer: () => void;
}

const mapFulfillments = (order: Order) =>
  order.fulfillments
    .map((fulfillment: Fulfillment) =>
      fulfillment.products.map((product: any) => ({
        ...product,
        isMealProduct: product.type === 'MEAL',
        tax_amount: parseNumber(
          order.products.find((orderProduct: OrderProduct) => orderProduct.code === product.code)
            ?.tax_amount
        ),
        fulfillment,
        order,
        unit_price: product.price.toFixed(2),
        discount_amount: parseNumber(product.discount_amount).toFixed(2),
      }))
    )
    .flat()
    .sort((a: any, b: any) => a.fulfillment.id.localeCompare(b.fulfillment.id));

const getPetNamesFromFulfillments = (petIds: string[], all_pets: Pet[]) => {
  if (!petIds) {
    return '--';
  }
  const petNames = new Set();
  petIds.forEach((pet_id: string) => {
    const name = all_pets.find((pet: Pet) => pet.id === pet_id)?.name;
    if (name) petNames.add(name);
  });

  const petNamesConcat = Array.from(petNames).join(', ');
  return petNamesConcat !== '' ? petNamesConcat : '--';
};

const getShipmentTooltip = (shipment: Shipment | undefined) => {
  if (!shipment) return 'No tracking information';

  const statusFields = [
    'shipped',
    'in_transit',
    'out_for_delivery',
    'available_for_pickup',
    'delivered',
    'voided',
    'returned',
    'exception',
  ] as const;
  type StatusField = (typeof statusFields)[number];

  let mostRecentStatus: string | undefined;
  let mostRecentTimestamp: Date | undefined;

  for (const status of statusFields) {
    const timestamp = shipment[status as StatusField];
    if (
      timestamp &&
      (!mostRecentTimestamp || new Date(timestamp) > new Date(mostRecentTimestamp))
    ) {
      mostRecentTimestamp = new Date(timestamp);
      mostRecentStatus = status;
    }
  }

  if (!mostRecentStatus) {
    return 'No tracking information';
  }

  return (
    <div className="text-left">
      Last update from AfterShip <br /> {mostRecentStatus} on{' '}
      {mostRecentTimestamp?.toLocaleString()}
    </div>
  );
};

const getTrackingNumber = (fulfillment: Fulfillment, isMealProduct: boolean) => {
  if (!fulfillment.is_split_fulfillment && (!isMealProduct || fulfillment.shipments.length === 0)) {
    return '--';
  }
  return (
    <ul className="flex flex-col items-center justify-center w-full">
      {fulfillment.shipments.map((shipment: Shipment) => (
        <li key={shipment.tracking_number} className="flex items-center justify-center">
          <a
            onClick={() => window.open(`${TRACKING_URL}/${shipment.tracking_number}`)}
            className="cursor-pointer underline text-anchor mr-2 text-center"
          >
            {shipment.tracking_number}
          </a>
          <InfoTooltip text={getShipmentTooltip(shipment)} />
        </li>
      ))}
    </ul>
  );
};

export const useSplitFulfillment = () => {
  const navigate = useNavigate();

  return (fulfillmentId: number) => {
    const curr = window.location.pathname;
    const fulfillmentPath = `${curr}/${fulfillmentId}/split-fulfillment`;
    navigate(fulfillmentPath);
  };
};
export const OrderFulfillments = ({ customer, order, refreshCustomer }: OrderFulfillmentsProps) => {
  const [selectedFulfilmentId, setSelectedFulfilmentId] = useState(null);
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  const canReplaceOrder =
    order.order_type !== 'REPLACEMENT' &&
    ['PARTIALLY_FULFILLED', 'FULFILLED', 'COMPLETE', 'CANCELLED'].some((status) =>
      order.status.includes(status)
    );
  const [showReplacementOrderModal, setShowReplacementOrderModal] = useState(false);
  const mappedFulfillments = mapFulfillments(order);

  const handleUpdateFulfillment = () => {
    setIsProcessLoading(true);
    axios
      .put(`/orders/mark_fulfillment_delivery_failed/`, { fulfillment_id: selectedFulfilmentId })
      .then(() => {
        refreshCustomer();
        setIsProcessLoading(false);
        setSelectedFulfilmentId(null);
        toast.success('Fulfillment status updated successfully', BOTTOM_TOAST);
      })
      .catch(() => {
        setIsProcessLoading(false);
        setSelectedFulfilmentId(null);
        toast.error('Failed to update fulfillment status', BOTTOM_TOAST);
      });
  };

  const getConfirmationTitle = () => {
    const fulfillment = order.fulfillments.find((fulfillment: Fulfillment) => {
      return fulfillment.id === selectedFulfilmentId;
    });
    return (
      <p>
        Mark {getPetNamesFromFulfillments(fulfillment?.pet_ids ?? [], customer.pets)}'s fulfillment
        as <span className="font-bold">“Delivery Failed”?</span>
      </p>
    );
  };

  const getConfirmationMessage = () => {
    return (
      <p>
        This action will update the fulfillment’s status from{' '}
        <span className="font-bold">“Shipped”</span> to{' '}
        <span className="font-bold">“Delivery Failed.”</span>
      </p>
    );
  };

  const isFulfillmentSplittable = (fulfillment: Fulfillment) => {
    return (
      !fulfillment.is_split_fulfillment &&
      fulfillment.status === FulfillmentStatus.ORDERDESK_SENT &&
      fulfillment.products.length > 1
    );
  };

  const getFulfillmentNumber = (product: any) => {
    if (product.fulfillment.is_split_fulfillment || product.isMealProduct) {
      return (
        <div className="flex items-center justify-center space-x-1 w-full">
          <span>{product.fulfillment?.status ? product.fulfillment?.status : ''}</span>
          {product.fulfillment?.status === 'SHIPPED' && (
            <a
              onClick={() => setSelectedFulfilmentId(product.fulfillment.id)}
              className="flex items-center justify-center cursor-pointer underline text-anchor"
            >
              <Pencil className="w-4 h-4 text-black" />
            </a>
          )}
        </div>
      );
    }
    return '--';
  };

  const parseProductItems = (product: FulfillmentProduct) => {
    if (!product || !Array.isArray(product.items) || product.items.length === 0) {
      return '--';
    }

    const itemStrings: string[] = [];

    product.items.forEach((item: Item) => {
      if (item.quantity && item.sku) {
        itemStrings.push(`${item.quantity} X ${item.sku}`);
      }
    });

    return itemStrings;
  };

  const seenFulfillmentIds = new Set<string>();

  return (
    <div className="pt-3 pb-6">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Fulfillments</b>
        {canReplaceOrder && (
          <div className="text-sm ml-2">
            <a
              onClick={() => setShowReplacementOrderModal(true)}
              className="cursor-pointer underline text-anchor"
            >
              Replace Order
            </a>
          </div>
        )}
      </h2>
      {showReplacementOrderModal && (
        <ReplacementOrderModal
          customer={customer}
          order={order}
          onConfirm={() => setShowReplacementOrderModal(false)}
          onCancel={() => setShowReplacementOrderModal(false)}
        />
      )}
      <div className="bg-white">
        <FulfillmentsTable
          header={FulfillmentHeader}
          data={mappedFulfillments.map((product: any) => {
            return {
              'Fulfillment ID': (() => {
                if (seenFulfillmentIds.has(product.fulfillment.id)) {
                  return '--';
                } else {
                  seenFulfillmentIds.add(product.fulfillment.id);
                  return product.fulfillment.id;
                }
              })(),
              Is_Meal_Product: product.isMealProduct,
              Is_Splittable: isFulfillmentSplittable(product.fulfillment),
              'Fulfillment Status': getFulfillmentNumber(product),
              'Shipment Tracking Number(s)': getTrackingNumber(
                product.fulfillment,
                product.isMealProduct
              ),
              'Pet(s)': getPetNamesFromFulfillments(product.fulfillment.pet_ids, customer.pets),
              'Product Code': product.code,
              'Product Description': product.name,
              Items: `${parseProductItems(product)}`,
              Price: <ProductPrice product={product} />,
              'Price with Tax': `$${calculatePriceAfterDiscountWithTax(product)}`,
            };
          })}
        />
      </div>
      {selectedFulfilmentId && (
        <ConfirmationModal
          isProcessLoading={isProcessLoading}
          title={getConfirmationTitle()}
          message={getConfirmationMessage()}
          confirmLabel="Confirm"
          onConfirm={handleUpdateFulfillment}
          onCancel={() => setSelectedFulfilmentId(null)}
        />
      )}
    </div>
  );
};
